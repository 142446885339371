import * as React from "react"
import {Link} from "gatsby"
import Layout from "../components/Layout/Layout";
import Nadpis from "../components/Layout/Nadpis";

const oNas = () => {
    return (
        <Layout>
            <Nadpis>
                <h1>Kontakt</h1>
            </Nadpis>
            <div className="container">
                <div className={"row"}>
                <div className={"col-md-6"}>
                    <h2>Kontaktní adresa</h2>
                    <address>
                        <span className={"text-primary"}>Haxo.cz s.r.o.</span><br/>
                        Městské sady 463/2<br/>
                        28401 Kutná Hora
                    </address>
                    <p>Zastihnete nás v pracovních dnech mezi 9:00 - 16:00</p>
                    <p>E-mail: <a href="mailto:global@haxo.cz">global@haxo.cz</a></p>
                </div>
                <div className={"col-md-6"}>
                    <h2>Fakturační adresa</h2>
                    <address>
                        <span className={"text-primary"}>Haxo.cz s.r.o.</span><br/>
                        Rybná 716/24<br/>
                        11000 Praha 1
                    </address>
                    <address>
                        IČ: 03484157<br/>
                        DIČ: CZ03484157
                    </address>
                    <p>
                        Zapsána v obchodním rejstříku u Městského soudu C vložka 232363.<br />
                        ID datové schránky: <strong>4ucq54v</strong>
                    </p>
                </div>
                </div>
            </div>
        </Layout>
    )
}

export default oNas
